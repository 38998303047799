<template>
  <!-- Contact Page Area Start -->
  <section id="contact" class="ekifa-contact-page-area section_80">
    <b-container>
      <b-row>
        <b-col md="6" lg="4">
          <div class="contact-page-left">
            <h3>contact info</h3>
            <div class="contact-info">
              <div class="single-contact-info">
                <div class="contact-info-icon">
                  <span class="fa fa-map-marker"></span>
                </div>
                <div class="contact-info-text">
                  <h4>Head office</h4>
                  <p>Center 114 - Main Road Office 12 - 2nd floor, Baabda, Lebanon</p>
                </div>
              </div>
              <div class="single-contact-info">
                <div class="contact-info-icon">
                  <span class="fa fa-phone"></span>
                </div>
                <div class="contact-info-text">
                  <h4>Phone</h4>
                  <p>(+961) 123 456 7896</p>
                  <p>(+961) 123 456 7890</p>
                </div>
              </div>
              <div class="single-contact-info">
                <div class="contact-info-icon">
                  <span class="fa fa-envelope"></span>
                </div>
                <div class="contact-info-text">
                  <h4>Email</h4>
                  <p>info@theassociates.me</p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="6" lg="6" offset-lg="1">
          <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1657.0836717672796!2d35.542857277302325!3d33.83379478732308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sMain%20Road%20Office%2012%20-%20Baabda%2C%20Lebanon!5e0!3m2!1sen!2sca!4v1683004501689!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="12" >
          <div class="contact-page-right">
            <h3>get in touch</h3>
            <b-alert v-model="showSuccessAlert" variant="success" dismissible>
              Message Sent Successfully!
            </b-alert>
            <b-form @submit.stop.prevent="onSubmit">
              <b-row>
                <b-col md="6">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="contact.name"
                        placeholder="Name"
                        :state="validateState('name')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      id="input-group-2"
                      label-for="input-2"
                  >
                    <b-form-input
                        id="input-2"
                        v-model="contact.email"
                        type="email"
                        placeholder="Email"
                        :state="validateState('email')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                      id="input-group-5"
                      label-for="input-5"
                  >
                    <b-form-input
                        id="input-5"
                        v-model="contact.phone"
                        placeholder="Phone"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group id="input-group-3" label-for="input-3">
                    <b-form-input
                        id="input-3"
                        v-model="contact.subject"
                        placeholder="Subject"
                        :state="validateState('subject')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="input-group-4" label-for="input-4">
                <b-form-textarea
                    id="input-4"
                    rows="8"
                    v-model="contact.message"
                    placeholder="Write Your Message Here"
                    :state="validateState('message')"
                ></b-form-textarea>
              </b-form-group>
              <b-button type="submit" name="submit">Send message</b-button>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Contact Page Area End -->
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "ContactArea",
  mixins: [validationMixin],
  validations: {
    contact: {
      name: {
        required
      },
      subject: {
        required
      },
      email: {
        required,
        email: email
      },
      message: {
        required
      }
    }
  },
  data() {
    return {
      contact: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      showSuccessAlert: false
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.contact[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.contact = {
        name: '',
        subject: '',
        email: '',
        phone: '',
        message: ''
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.contact.$touch();
      if (this.$v.contact.$anyError) {
        return;
      }
      this.showSuccessAlert = true;
      this.resetForm();
    }
  }
}
</script>

<style scoped>

</style>