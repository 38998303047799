<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Contact ARea Component -->
  <contact-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import ContactArea from "../components/ContactArea";

export default {
  name: "Contact",
  components: {ContactArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Contact Us",
        pageTitle: "Contact Us"
      }
    }
  }
}
</script>

<style scoped>

</style>